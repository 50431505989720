<template>
    <div class="chart chart-bar" ref="column"></div>
</template>

<script>
import '@toast-ui/chart/dist/toastui-chart.min.css';
import {ColumnChart} from '@toast-ui/chart';
import {onMounted, onUnmounted, reactive, ref} from 'vue';

export default {
  name: "ToastColumnChart",
  props: {
    categories: Array,
    series: Array,
    options: {
      type: Object,
      default: () => {
        return {
          chart: { width: '100%', height: '100%' },
          xAxis: {
            pointOnColumn: false,
            // title: { text: 'Month' }
          },
          // yAxis: { title: 'Temperature (Celsius)' },
          theme: {
            series: {
              areaOpacity: 0.7,
              colors: ['#ffcc00', '#e8e8e8'],
              barWidth: 30
            }
          },
          exportMenu: {
            visible: false
          },
          lang: {
            noData: '😭 데이터가 없습니다.'
          },
          usageStatistics: false
        }
      }
    },
  },
  setup(props){
    const column = ref(null);
    const chart = ref(null);

    const data = {
      categories: props.categories,
      series: props.series
    };

    const options = reactive(props.options);

    onMounted(()=>{
      const el = column.value;
      chart.value = new ColumnChart({el, data, options});
    });
    onUnmounted(()=>{
    });

    return {
      column
    }
  }
}
</script>
